import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Empty from '../../Components/Empty/Index';
import SweetAlert from 'sweetalert2';
import { getusers, ModalToggle, isOpenModal, addusers, updateusers, deleteusers, } from '../../store/userSlice';

const UsersTable = () => {
    const storeVar = useSelector(state => state.users)
    const dispatch = useDispatch();
    const toggle = () => dispatch(ModalToggle());
    const [formVar, setFormVar] = useState({
        modalTitle: null,
        editState: false,
        id: '',
        email: '',
        phoneNumber: '',
        profileImage: '',
        status: '',
        addresses: '',
        createdAt: '',
        name: '',
        userDetails: {
            wallet: '',
            gender: '',
            dateOfBirth: '',
        }
    });

    useEffect(() => {
        dispatch(getusers())
    }, []);

    return (
        <Fragment>
            <Col sm='12'>
                <Card>
                    <div className='table-responsive'>
                        <Table hover={true} className='table-border-horizontal table-light'>
                            <thead>
                                <tr>
                                    <th scope='col'>Sl.No</th>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Email</th>
                                    <th scope='col'>Phone Number</th>
                                    <th scope='col'>Profile Image</th>
                                    <th scope='col'>Wallet</th>
                                    <th scope='col'>Gender</th>
                                    <th scope='col'>Date of Birth</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeVar.usersData?.map((item, index) => (
                                    <tr key={item._id}>
                                        <th scope='row'>{index + 1}</th>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phoneNumber}</td>
                                        <td><img src={item.profileImage} /></td>
                                        <td>{item?.userDetails?.wallet ? `${item.userDetails.wallet}`:'-'}</td>
                                        <td>{item?.userDetails?.gender ? `${item.userDetails.gender}`:'-'}</td>
                                        <td>{item?.userDetails?.dateOfBirth? `${item.userDetails.dateOfBirth}`: '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    {
                        storeVar.usersData.length <= 0 && (
                            <Empty />
                        )
                    }
                </Card>
            </Col>

        </Fragment>
    );
};

export default UsersTable;
