import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading'
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  questionData: [],
  isOpenModal: false,
}

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setquestionData(state, { payload }) {
      state.questionData = payload
    },
    updatequestionsData(state, { payload }) {
      const objIndex = state.questionData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.questionData[objIndex].name = payload.name
      }
    },
    pushQuestionData(state, { payload }) {
      state.questionData.push(payload)
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
  }
})

export const { setquestionData, pushQuestionData, updatequestionsData, isOpenModal, ModalToggle, isOpenStatusModal, statusToggle } = questionSlice.actions;

export default questionSlice.reducer;

export function getquestion(examId) {
  return async function getquestionThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.fetchQuestions(examId).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setquestionData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function addquestionData(payload) {
  return async function addquestionThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.addquestion(payload).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          dispatch(pushQuestionData(response.data))
          successHandler('Added Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
export function updatequestionData(id, name) {
  return async function updatequestionThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatequestion(id, name).then(
        (response) => {
          dispatch(updatequestionsData(response.data))
          dispatch(setLoading(false))
          dispatch(ModalToggle())
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

export function statusDeletequestionStatus(id, status) {
  return async function statusDeletequestionsThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.updatequestionStatusData(id, status).then(
        (response) => {
          dispatch(updatequestionsData(response.data))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}
