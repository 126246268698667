import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Empty from '../../Components/Empty/Index';
import SweetAlert from 'sweetalert2';
import { getspecialization, ModalToggle, isOpenModal, addspecialization, updatespecialization, deletespecialization, } from '../../store/specializationSlice';




const SpecializationTable = () => {
  const storeVar = useSelector(state => state.specialization)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const [submit, setSubmit] = useState(false);
  const [formVar, setFormVar] = useState({
    modalTitle: null,
    editState: false,
    id: '',
    name: '',
  });

  useEffect(() => {
    dispatch(getspecialization())
  }, []);





  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      id: data._id,
      modalTitle: 'Edit Specialization',
      name: data.name,
    }))
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Specialization',
      name: '',
    }))
  }

  const DeleteModel = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(deletespecialization(data._id,))
        }
      });
  }



  const submitMenu = () => {
    if (NameValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updatespecialization(formVar.id, { name: formVar.name }))
    } else {
      dispatch(addspecialization({ name: formVar.name }))
    }
  }


  const NameValid = () => {
    if (!formVar.name) {
      return "Name is required";
    }
  }
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
              </Col>
              <Col md="3">
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2 w-100">
                  <Btn attrBtn={{ color: 'info-gradien', className: 'w-100', size: 'sm', onClick: AddToggleModal }}>
                    Add Specialization
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.specializationData?.map((item, index) => (
                  <tr key={item._id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.name}</td>

                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-edit fa-lg" onClick={(e) => EditToggleModal(item)}></i>
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-danger action-icon'>
                          <i className="fa fa-trash fa-lg" onClick={(e) => DeleteModel(item)}></i>
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.specializationData.length <= 0 && (
              <Empty />
            )
          }
        </Card>
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="recipient-name">Name</Label>
            <Input className="form-control" type="text" placeholder='Enter Specialization Name' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
            {submit && NameValid() ? <span className='d-block font-danger'>{NameValid()}</span> : ""}
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitMenu }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

    </Fragment>
  );
};

export default SpecializationTable;
