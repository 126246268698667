
import { createSlice } from '@reduxjs/toolkit';
import { service } from '../shared/_services/api_service';
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const STATUS = Object.freeze({
  IDLE: 'idle',
  ERROR: 'error',
  LOADING: 'loading',
});

const initialState = {
  loadingStatus: STATUS.IDLE,
  error: '',
  queryData: [],
  queryCount: 0,
};

export const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setquery(state, { payload }) {
      state.queryData = payload.result;
      state.queryCount = payload.count;
    },
    slicequeryData(state, { payload }) {
      const objIndex = state.queryData.findIndex((obj) => obj._id === payload);
      if (objIndex >= 0) {
        state.queryData.splice(objIndex, 1)
      }
    },
  },
});

export const { setquery, slicequeryData } = querySlice.actions;
export default querySlice.reducer;

export function fetchQuery(limit, offset) {
  return async function fetchquerysThunk(dispatch, getState) {
    try {
      await service.getQuery(limit, offset).then(
        (response) => {

          dispatch(setquery(response.data));
        }, (error) => {
        }
      );

    } catch (err) {

    }
  }
}

export function DeleteQuery(id) {
  return async function DeleteQueryThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.deleteQueryStatus(id).then(
        (response) => {
          dispatch(slicequeryData(id))
          dispatch(setLoading(false))
          successHandler('Deleted Successfully')
        }, (error) => {
          dispatch(setLoading(false))
          errorHandler(error.response)
        }
      );
    } catch (err) {

    }
  }
}

