import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice';
import loadingReducer from './loader';
import dashboardReducer from './dashboardSlice';
import astrologerReducer from './astrologerSlice';
import specializationReducer from './specializationSlice';
import categoryReducer from './categorySlice';
import subCategoryReducer from './subCategorySlice';
import productsReducer from './productsSlice';
import productImageReducer from './productImageSlice';
import examReducer from './examSlice';
import questionReducer from './questionSlice';
import answerReducer from './answerSlice';
import blogReducer from './blogSlice';
import bannerReducer from './bannerSlice';
import podcastReducer from './podcastSlice';
import queryReducer from './querySlice';
import couponReducer from './couponSlice';
import ordersReducer from './ordersSlice';
import usersReducer from './userSlice';
import TransactionHistoryReducer from './transactionHistorySlice';
import bankDetailsReducer from './bankDetailsSlice';
import streamReducer from './streamSlice';
import languageReducer from './languageSlice';
import staffReducer from './staffSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loadingReducer,
    dashboard: dashboardReducer,
    astrologer: astrologerReducer,
    specialization: specializationReducer,
    category: categoryReducer,
    subcategory: subCategoryReducer,
    products: productsReducer,
    productImage: productImageReducer,
    exam: examReducer,
    question: questionReducer,
    answer: answerReducer,
    banner: bannerReducer,
    blog: blogReducer,
    podcast: podcastReducer,
    query: queryReducer,
    coupon: couponReducer,
    order: ordersReducer,
    users: usersReducer,
    transactionHistory: TransactionHistoryReducer,
    bankDetails: bankDetailsReducer,
    staff: staffReducer,



    stream: streamReducer,
    language: languageReducer,
  },
})

export default store;