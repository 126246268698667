import React, { Fragment, useEffect } from 'react';
import { Col, Card, Table, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchOrderDetails } from '../../../store/ordersSlice';
import moment from 'moment';


const OrderDetailsTable = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const storeVar = useSelector(state => state.order)
    const paymentId = new URLSearchParams(location.search).get('id');

    useEffect(() => {
        dispatch(fetchOrderDetails(paymentId))
    }, []);

    return (
        <Fragment>
            <Card>
                <Row>
                    <Col md="4">
                        {"Order Id:- " + storeVar.orderDetails.orderId}
                    </Col>
                    <Col md="4">
                        {"payment Id:- " + storeVar.orderDetails.transactionId}
                    </Col>
                    <Col md="4">
                        {"Order Status:- " + storeVar.orderDetails?.status?.toUpperCase()}
                    </Col>
                    <Col md="4">
                        {"Order date:- " + moment(storeVar.orderDetails.orderedTime).format('HH:mm Do MMM YY')}
                    </Col>
                    <Col md="4">
                        {"Mrp:- " + storeVar.orderDetails.totalMrp}
                    </Col>
                    <Col md="4">
                        {"Discount:- " + storeVar.orderDetails.discount}
                    </Col>
                    <Col md="4">
                        {"Total:- " + storeVar.orderDetails.totalFinalPrice}
                    </Col>
                    <Col md="4">
                        {"Coupon Code:- " + (storeVar.orderDetails.couponCode?.code || 'No Coupons')}
                    </Col>
                </Row>
            </Card>
            <div className='table-responsive'>
                <Table hover={true} className='table-border-horizontal table-light'>
                    <thead>
                        <tr>
                            <th scope='col'>Sl.No</th>
                            <th scope='col'>Name</th>
                            <th scope='col'>SkuId</th>
                            <th scope='col'>Image</th>
                            <th scope='col'>Desc</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storeVar.orderDetails.product?.map((item, index) => (
                            <tr key={item._id}>
                                <th scope='row'>{index + 1}</th>
                                <td>{item.productId.name}</td>
                                <td>{item.productId.skuId}</td>
                                <td className='w-25'>
                                    <img className='w-80 h-6-r' src={item.productId?.images[0]?.image} alt="" />
                                </td>
                                <td>{item.productId.desc }</td>
                                
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
};

export default OrderDetailsTable;