import { localService } from "../../shared/_session/local";


// export const MENUITEMS = [
//   {
//     menutitle: "Dashboard",
//     menucontent: "Dashboards",
//     Items: [
//       { path: `/dashboard`, icon: "home", type: "sub", activeTitle: "Dashboard", title: "Dashboard", type: "link", show: true },


//     ],
//   },
//   {
//     menutitle: "General",
//     menucontent: "Dashboards,Widgets",
//     Items: [
//       { path: `/specialization`, icon: "specialization", activeTitle: "specialization", title: "Specialization", type: "link", show: true },
//       { path: `/staff`, icon: "specialization", activeTitle: "specialization", title: "Staff", type: "link", show: true },
//       { path: `/exams`, icon: "blogs", activeTitle: "exams", title: "Exam", type: "link", show: true },
//       { path: `/astrologers`, icon: "specialization", activeTitle: "astrologers", title: "Astrologers", type: "link", show: true },

//       { path: `/category`, icon: "blogs", activeTitle: "category", title: "Category", type: "link", show: true },
//       { path: `/subcat`, icon: "blogs", activeTitle: "subcat", title: "SubCategory", type: "link", show: true },
//       { path: `/products`, icon: "blogs", activeTitle: "products", title: "Products", type: "link", show: true },
//       { path: `/blogs`, icon: "blogs", activeTitle: "blogs", title: "Blogs", type: "link", show: true },
//       { path: `/podcast`, icon: "blogs", activeTitle: "podcast", title: "Podcast", type: "link", show: true },
//       { path: `/banners`, icon: "blogs", activeTitle: "banners", title: "Banners", type: "link", show: true },
//       { path: `/coupons`, icon: "specialization", activeTitle: "coupons", title: "Coupons", type: "link", show: true },
//       { path: `/orders`, icon: "blogs", activeTitle: "orders", title: "Orders", type: "link", show: true },
//       { path: `/transactions`, icon: "blogs", activeTitle: "transactions", title: "Transactions", type: "link", show: true },
//       { path: `/users`, icon: "blogs", activeTitle: "users", title: "Users", type: "link", show: true },
//       { path: `/query`, icon: "blogs", activeTitle: "query", title: "Query", type: "link", show: true },

//     ],
//   },

// ];


const hasPermission = (permission) => {
  const userType = localService.get('type');
  const permissions = localService.get('permissions') || [];
  return userType === 'admin' || (userType === 'staff' && permissions.includes(permission));
};

export const MENUITEMS = [
  {
    menutitle: "Dashboard",
    menucontent: "Dashboards",
    Items: [
      {
        path: `/dashboard`,
        icon: "home",
        activeTitle: "Dashboard",
        title: "Dashboard",
        type: "link",
        show: true,
      },
    ],
  },
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        path: `/specialization`,
        icon: "specialization",
        activeTitle: "specialization",
        title: "Specialization",
        type: "link",
        show: hasPermission('Specialization'),
      },
      {
        path: `/staff`,
        icon: "specialization",
        activeTitle: "staff",
        title: "Staff",
        type: "link",
        show: hasPermission('Staff'),
      },
      {
        path: `/exams`,
        icon: "blogs",
        activeTitle: "exams",
        title: "Exam",
        type: "link",
        show: hasPermission('Exam'),
      },
      {
        path: `/astrologers`,
        icon: "specialization",
        activeTitle: "astrologers",
        title: "Astrologers",
        type: "link",
        show: hasPermission('Astrologers'),
      },
      {
        path: `/category`,
        icon: "blogs",
        activeTitle: "category",
        title: "Category",
        type: "link",
        show: hasPermission('Category'),
      },
      {
        path: `/subcat`,
        icon: "blogs",
        activeTitle: "subcat",
        title: "Sub Category",
        type: "link",
        show: hasPermission('Subcategory'),
      },
      {
        path: `/products`,
        icon: "blogs",
        activeTitle: "products",
        title: "Products",
        type: "link",
        show: hasPermission('Products'),
      },
      {
        path: `/blogs`,
        icon: "blogs",
        activeTitle: "blogs",
        title: "Blogs",
        type: "link",
        show: hasPermission('Blogs'),
      },
      {
        path: `/podcast`,
        icon: "blogs",
        activeTitle: "podcast",
        title: "Podcast",
        type: "link",
        show: hasPermission('Podcast'),
      },
      {
        path: `/banners`,
        icon: "blogs",
        activeTitle: "banners",
        title: "Banners",
        type: "link",
        show: hasPermission('Banners'),
      },
      {
        path: `/coupons`,
        icon: "specialization",
        activeTitle: "coupons",
        title: "Coupons",
        type: "link",
        show: hasPermission('Coupons'),
      },
      {
        path: `/orders`,
        icon: "blogs",
        activeTitle: "orders",
        title: "Orders",
        type: "link",
        show: hasPermission('Orders'),
      },
      {
        path: `/transactions`,
        icon: "blogs",
        activeTitle: "transactions",
        title: "Transactions",
        type: "link",
        show: hasPermission('Transactions'),
      },
      {
        path: `/users`,
        icon: "blogs",
        activeTitle: "users",
        title: "Users",
        type: "link",
        show: hasPermission('Users'),
      },
      {
        path: `/query`,
        icon: "blogs",
        activeTitle: "query",
        title: "Query",
        type: "link",
        show: hasPermission('Query'),
      },
    ],
  },
];
