import { createSlice } from '@reduxjs/toolkit'
import { service } from '../shared/_services/api_service'
import { setLoading } from './loader';
import { errorHandler, successHandler } from '../shared/_helper/responseHelper';

const initialState = {
  astrologerData: [],
  astrologerDetails: [],
  totalastrologer: 0,
  isOpenModal: false,
  isStatusOpenModal: false,
  isexamStatusOpenModal: false,
  isvideoExamStatusOpenModal: false,

}

export const astrologerSlice = createSlice({
  name: "astrologer",
  initialState,
  reducers: {
    setastrologerData(state, { payload }) {
      state.astrologerData = payload.result
      state.totalastrologer = payload.totalCount
    },
    setAstrologerDetails(state, { payload }) {
      console.log(payload);
      
      state.astrologerDetails = payload.result
    },
    updateAstrologerStatusData(state, { payload }) {
      const objIndex = state.astrologerData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.astrologerData[objIndex].status = payload.status
      }
    },
    updateastrologerData(state, { payload }) {
      const objIndex = state.astrologerData.findIndex((obj) => obj.id === payload.id);
      if (objIndex >= 0) {
        state.astrologerData[objIndex] = {
          ...state.astrologerData[objIndex],
          chatPrice: payload.data.chatPrice ?? state.astrologerData[objIndex].chatPrice,
          callPrice: payload.data.callPrice ?? state.astrologerData[objIndex].callPrice,
          pricePerMinute: payload.data.pricePerMinute ?? state.astrologerData[objIndex].pricePerMinute,
          videoPrice: payload.data.videoPrice ?? state.astrologerData[objIndex].videoPrice,
          level: payload.data.level ?? state.astrologerData[objIndex].level,
        };
      }
    },
    updateAstrologerExamData(state, { payload }) {
      const objIndex = state.astrologerData.findIndex((obj) => obj.id === payload.id);

      if (objIndex >= 0) {
        state.astrologerData[objIndex] = {
          ...state.astrologerData[objIndex],
          examScoreApproved: payload.status ?? state.astrologerData[objIndex].examScoreApproved,

        };
      }
    },
    updateAstrologerVideoExamData(state, { payload }) {
      const objIndex = state.astrologerData.findIndex((obj) => obj.id === payload.id);

      if (objIndex >= 0) {
        state.astrologerData[objIndex] = {
          ...state.astrologerData[objIndex],
          videocallApproved: payload.status ?? state.astrologerData[objIndex].videocallApproved,

        };
      }
    },
    isOpenModal(state, { payload }) {
      state.isOpenModal = payload
    },
    ModalToggle(state, { payload }) {
      state.isOpenModal = !state.isOpenModal
    },
    isOpenStatusModal(state, { payload }) {
      state.isStatusOpenModal = payload
    },
    statusToggle(state, { payload }) {
      state.isStatusOpenModal = !state.isStatusOpenModal
    },
    isOpenExamStatusModal(state, { payload }) {
      state.isexamStatusOpenModal = payload
    },
    examToggle(state, { payload }) {
      state.isexamStatusOpenModal = !state.isexamStatusOpenModal
    },
    isOpenVideoExamStatusModal(state, { payload }) {
      state.isvideoExamStatusOpenModal = payload
    },
    videoExamToggle(state, { payload }) {
      state.isvideoExamStatusOpenModal = !state.isvideoExamStatusOpenModal
    },
  }
})

export const { setastrologerData, setAstrologerDetails, updateAstrologerStatusData, updateAstrologerExamData, updateAstrologerVideoExamData, updateastrologerData, isOpenModal,
  ModalToggle, isOpenStatusModal, statusToggle, examToggle, isOpenExamStatusModal,isOpenVideoExamStatusModal, videoExamToggle, } = astrologerSlice.actions;

export default astrologerSlice.reducer;

export function getastrologer(limit, offset, status, keyword) {
  return async function getastrologerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.getastrologer(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setastrologerData(response.data))
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}

export function updateAstrologer(id, data) {
  return async function updateastrologerThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.updateastrologer(id, data).then(
        (response) => {
          dispatch(ModalToggle())
          dispatch(setLoading(false))
          dispatch(updateastrologerData({ id, data }))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateastrologer(payload) {
  return async function statusUpdateastrologerThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateastrologer(payload.id, payload.status).then(
        (response) => {
          dispatch(statusToggle())
          dispatch(updateAstrologerStatusData(payload))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}

export function statusUpdateExamAstrologer(payload) {  
  return async function statusUpdateExamAstrologerThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateExamAstrologer(payload.id, payload.status).then(
        (response) => {
          dispatch(examToggle())
          dispatch(updateAstrologerExamData(payload))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}
export function statusUpdateVideoExamAstrologer(payload) {  
  return async function statusUpdateVideoExamAstrologerThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateVideoExamAstrologer(payload.id, payload.status).then(
        (response) => {
          dispatch(videoExamToggle())
          dispatch(updateAstrologerVideoExamData(payload))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}
export function takeExamAstrologer(payload) {  
  return async function takeExamAstrologerThunk(dispatch) {
    try {
      dispatch(setLoading(true))
      await service.statusUpdateExamAstrologer(payload.id, payload.status).then(
        (response) => {
          dispatch(updateAstrologerExamData(payload))
          dispatch(setLoading(false))
          successHandler('Updated Successfully')
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}


export function getastrologerById(limit, offset, status, keyword) {
  return async function getAstrologerByIdThunk(dispatch) {
    dispatch(setLoading(true))
    try {
      await service.fetchAstrologerById(limit, offset, status, keyword).then(
        (response) => {
          dispatch(setLoading(false))
          dispatch(setAstrologerDetails(response.data))
        }, (error) => {
          dispatch(setLoading(false))
        }
      );
    } catch (err) {

    }
  }
}